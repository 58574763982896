// General
import { RX_STATUS, RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';
import { navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { sortBy } from 'lodash';
// Hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

// Components, Helpers & UI Kit
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { accountFetchPlansRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountDefaultAddressSelector,
    accountHasInsuranceSelector,
    accountIsCaliforniaUserSelector,
    accountIsLoadingPaymentMethods,
    accountPlansSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getCartRoutine, startCartRoutine, updateRefillLinesRoutine } from 'state/cart/cart.routines';
import {
    cartItemsSelector,
    cartOrderBillShipSelector,
    cartOrderShippingAddressSelector,
    cartSelector
} from 'state/cart/cart.selectors';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { familyMembersPlansSelector } from 'state/family-profile/family-profile.selectors';
import {
    setMedicineCabinetActiveTab,
    setMedicineCabinetFilterTab,
    setMedicineCabinetIsBusy
} from 'state/medicine-cabinet/medicine-cabinet.reducers';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetAutoRefillToggleBusySelector,
    medicineCabinetFilteredRxsForStatusSelector,
    medicineCabinetFilterTabSelector,
    medicineCabinetIsBusySelector,
    medicineCabinetIsLoadingSelector,
    medicineCabinetPopulatedSelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

import { RxDetails } from 'types/prescription';
// Interfaces and Types
import { PrescriptionCardProps } from 'types/prescription';

// Utils
import { lowercaseAndCapitalize, processCart } from 'util/cart';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { prescriptionPayloadToProps } from 'util/payload-to-props';
import { hasPlanAutoRefillFlag, isRxExpired } from 'util/prescription';
import { isBirdiCashPlan } from 'util/pricing';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';

import PrescriptionTabs from './prescription-tabs/prescription-tabs.component';
import PrescriptionCard from './prescriptions-card/prescriptions-card.component';
import PrescriptionsCards from './prescriptions-cards/prescriptions-cards.component';
// Styles
import './prescriptions-list.style.scss';

// Main component interface
interface PrescriptionsListProps {
    pillImage: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'id'>>;
}

// Main component
const PrescriptionsList: React.FC<PrescriptionsListProps> = ({ pillImage }) => {
    // --------------------
    // Hooks
    // -------------------
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const { handleToggleAutoRefill } = useAutoRefillToggle();

    // --------------------
    // Selectors
    // -------------------

    // Drug Selectors
    const { drugDiscountPrices } = useSelector(drugSelector);

    // Cart Selectors
    const cartItemsObject = useSelector(cartItemsSelector);
    const cartObject = useSelector(cartSelector);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);

    // Account Selectors
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const isLoadingPaymentData = useSelector(accountIsLoadingPaymentMethods);
    const profileObject = useSelector(accountProfileSelector);
    const isCaliforniaUser = useSelector(accountIsCaliforniaUserSelector);
    const defaultAddress = useSelector(accountDefaultAddressSelector);
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);

    const zipcode = cardOrderShippingAddress?.zipcode || defaultAddress?.zipcode || '';

    // Membership Selectors
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);

    // Medicine cabinet Selectors
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);
    const medicineCabinetFilteredRxsForStatus = useSelector(medicineCabinetFilteredRxsForStatusSelector);
    const medicineCabinetPopulated = useSelector(medicineCabinetPopulatedSelector);
    const prescriptionsSelector = useSelector(medicineCabinetPrescriptionsSelector);
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const filterTab = useSelector(medicineCabinetFilterTabSelector);
    const medicineCabinetIsLoading = useSelector(medicineCabinetIsLoadingSelector);

    // Family Members Selectors
    const familyMembers = useSelector(familyMembersPlansSelector);

    // --------------------
    // Local state
    // -------------------
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isAddingToCart, setIsAddingToCart] = useState(true);
    const [rxsLoaded, setRxsLoaded] = useState(false);
    const [prescriptions, setPrescriptions] = useState<RxDetails[]>([]);

    const [filteredRxs, setFilteredRxs] = useState<PrescriptionCardProps[] | null>(null);
    const [orderedRxs, setOrderedRxs] = useState<PrescriptionCardProps[] | null>(null);
    const [initialRxLoaded, setInitialRxLoaded] = useState(false);

    // activePlanAlias is used to define if the price should be available for the current dependent
    const [activePlanAlias, setActivePlanAlias] = useState<string>();
    const [discountPriceEligiblePrescriptions, setDiscountPriceEligiblePrescriptions] = useState<RxDetails[]>([]);

    // --------------------
    // Memoized values
    // -------------------
    const buttonLabel = useMemo(
        () =>
            isCollapsed
                ? t('components.prescriptionsList.mobile.ctas.expandCards')
                : t('components.prescriptionsList.mobile.ctas.collapseCards'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isCollapsed]
    );

    // TODO: Check if this can be moved to sagas instead
    const dependentTabs = useMemo(() => {
        if (!profileObject?.isCaregiver) return [];

        // If the user is a caregiver try to load the
        // dependents information from dependents API
        // but if cart loads data first, the dependents
        // list can be loaded from that array too.
        if (familyMembers && familyMembers.length > 0) {
            return familyMembers?.map((dependent, i) => ({
                ePostPatientNum: `${dependent.value}`,
                familyMemberName: dependent.label,
                id: i
            }));
        }
        if (cartObject && cartObject.length > 0) {
            return cartObject?.map((cart, i) => ({
                ePostPatientNum: `${cart.EpostPatientNum}`,
                familyMemberName: lowercaseAndCapitalize(`${cart.FirstName} ${cart.LastName}`),
                id: i
            }));
        }

        return [];
    }, [profileObject, familyMembers, cartObject]);

    // --------------------
    // Use effect hooks
    // -------------------

    // By default the 'isAddingToCart' value is true
    // but use effects helps us to update this value when
    // medicine cabinet selector response.
    useEffect(() => {
        setIsAddingToCart(prescriptionsIsBusySelector);
    }, [prescriptionsIsBusySelector]);

    // Instead using Prescription Selector data to list all prescriptions,
    // we store its data in a state to be possible to manipulate some parameters
    // without the need of sending a new dispatch do collect new data to know
    // what prescriptions were added to the cart.
    //
    // Ticket implementation: DRX-1898
    useEffect(() => {
        let prescriptionList = prescriptionsSelector;

        // Ticket implementation: DRX-1898
        // Each time that the Cart Object is updated, instead of requesting a new
        // list of prescriptions for the API and wait its result to sort the items,
        // we get the list of prescriptions stored inside the selector and change the
        // inOrderCart manually to be possible to update the card type and its order.
        //
        // Fix implementation: DRX-2319
        // The conditional was moved from its own useEffect and grouped with the
        // prescriptionsSelector to fix the changing status after autorefill toggle.
        if (cartItemsObject && Array.isArray(cartItemsObject)) {
            prescriptionList = prescriptionsSelector.map((prescription) => {
                const isInCart = cartItemsObject.find((rx) => rx.rxNumber === prescription.rxNumber);

                // The prescription old data is used to return the prescription to its old order this is
                // defined based on more than one information combined. Setting the prescription old data
                // makes possible to give back the old position for this prescription after being remove from
                // the cart.
                //
                // This does not work for items that were already loaded at the cart during the page load, because
                // we will discover the prescription real status just after receiving the data from API.
                const prescriptionOldData = prescription?.prescriptionOldData ? prescription?.prescriptionOldData : {};

                if (isInCart) {
                    return { ...prescription, inOrderCart: true, prescriptionOldData: { ...prescription } };
                } else {
                    return { ...prescription, ...prescriptionOldData, inOrderCart: false };
                }
            });
        }

        setPrescriptions(prescriptionList);
    }, [prescriptionsSelector, cartItemsObject]);

    // Each time that the list of prescriptions is updated, we sort the order
    // for all prescriptions based on its processed props.
    //
    // We sort the prescriptions by sortOrder and prescriptionName because the
    // API returns a different order each time that it sand back the data, and
    // this was causing some flickering during the sort process. To avoid this, we
    // standardized the order using these two fields.
    //
    // Ticket implementation: DRX-1898
    useEffect(() => {
        const prescriptionProps: PrescriptionCardProps[] = prescriptions.map((prescription) =>
            prescriptionPayloadToProps(
                prescription,
                t,
                activePlanAlias,
                // TODO: For now we are using on demand validation of the
                // parent's user, to check if we need to do it at the dependent level
                isOnDemandPlan
            )
        );
        const orderedPrescriptionProps = sortBy(prescriptionProps, ['sortOrder', 'prescriptionName']);

        setOrderedRxs(orderedPrescriptionProps);
        setFilteredRxs(orderedPrescriptionProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriptions]);

    // Use effect to load plan data and credit cards
    useEffect(() => {
        if (profileObject !== undefined) {
            if (!allPaymentData && !isLoadingPaymentData) {
                dispatch(accountGetAllCreditCardsRoutine.trigger()); // needed to init Cart and New/Transfer workflows
            }

            dispatch(setMedicineCabinetIsBusy(false));

            if (!activePlanAlias) {
                setActivePlanAlias(profileObject?.planAlias);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlanAlias, allPaymentData, isLoadingPaymentData, profileObject]);

    useEffect(() => {
        if (profileObject !== undefined && (!accountPlans || accountPlans.length === 0)) {
            if (profileObject.isCaregiver && familyMembers) {
                dispatch(accountFetchPlansRoutine({ familyMembers }));
            } else if (profileObject.epostPatientNum && !profileObject.isCaregiver) {
                dispatch(accountFetchPlansRoutine({ familyMembers: [profileObject.epostPatientNum] }));
            }
            dispatch(setMedicineCabinetIsBusy(false));
        }
    }, [profileObject, accountPlans, familyMembers, dispatch]);

    useEffect(() => {
        if (medicineCabinetPopulated) {
            if (profileObject?.isCaregiver) {
                medicineCabinetFilteredRxsForStatus
                    .map((rx) => rx.rxNumber)
                    .forEach((rxNumber) =>
                        dispatch(
                            medicineCabinetGetStatusForRx.trigger({
                                rxNumber,
                                epostNumFamilyMember: activeDependentTab
                            })
                        )
                    );
            } else {
                medicineCabinetFilteredRxsForStatus
                    .map((rx) => rx.rxNumber)
                    .forEach((rxNumber) => dispatch(medicineCabinetGetStatusForRx.trigger({ rxNumber })));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicineCabinetPopulated]);

    useEffect(() => {
        // Set the rxs eligibles for pricing.
        if (orderedRxs && orderedRxs.length > 0 && profileObject !== undefined) {
            if (
                (accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE) ||
                (activePlanAlias && isBirdiCashPlan(activePlanAlias))
            ) {
                const priceEligiblePrescriptions: RxDetails[] = orderedRxs
                    .filter((rx) => rx.showPrice)
                    .map((rx) => rx.fullRxItem);
                setDiscountPriceEligiblePrescriptions(priceEligiblePrescriptions);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderedRxs, orderBillShip]);

    useEffect(() => {
        // Get discount drug prices after the profile and
        // all prescriptions are loaded.
        if (discountPriceEligiblePrescriptions.length > 0) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: discountPriceEligiblePrescriptions,
                    forceBirdiInsurance: !!(accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'MedCabinet'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountPriceEligiblePrescriptions]);

    // Updates filter data based on Rxs received and tabs
    // TODO: Review how many times this code is being triggered and optimize it
    useEffect(() => {
        async function filterData() {
            const filtered = await handleFilterPrescriptions(orderedRxs, filterTab, activeDependentTab);
            setFilteredRxs(filtered);
        }
        filterData();
    }, [activeDependentTab, filterTab, orderedRxs, prescriptions]);

    // Load Rxs for the default user.
    useEffect(() => {
        if (profileObject && !initialRxLoaded) {
            handleTabItemClick(
                filterTab,
                activeDependentTab === '' ? profileObject?.epostPatientNum : activeDependentTab
            );
            setInitialRxLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartObject, profileObject]);

    // --------------------
    // Callbacks
    // -------------------

    const isRxInCartItemsObject = useCallback(
        (rxNumber: string) => (cartItemsObject ?? []).some((cartItem) => cartItem.rxNumber === rxNumber),
        [cartItemsObject]
    );

    const isRxInCart = useCallback(
        (prescription: PrescriptionCardProps) => {
            return (
                (isRxInCartItemsObject(prescription.rxNumber) || prescription.orderStatus === RX_STATUS.IN_CART) &&
                prescription.orderStatus !== RX_STATUS.TRANSFER_PENDING
            );
        },
        [isRxInCartItemsObject]
    );

    // Add to cart functions
    const handleAddToCartClick = useCallback(
        (rxNumber: string, epostPatientNum: string) => {
            dispatch(setMedicineCabinetIsBusy(true));
            let cartRoutineSwitch;
            let rxInCart = false;

            if (!cartItemsObject || cartItemsObject?.length === 0) {
                cartRoutineSwitch = startCartRoutine;
            } else {
                cartRoutineSwitch = updateRefillLinesRoutine;
                // Make sure item isn't already in cart, due to super-fast clicking. TODO: move to saga
                //cast alreadyInCart = orderObject.refillRxs.find(action.payload.rxNumber)
                if (cartItemsObject) {
                    rxInCart = isRxInCartItemsObject(rxNumber);
                }
            }

            if (!rxInCart) {
                dispatch(
                    cartRoutineSwitch.trigger({
                        rxNumber: rxNumber,
                        epostPatientNum: epostPatientNum,
                        onFailure: (data: any) => {
                            dispatch(setMedicineCabinetIsBusy(false));
                            dispatch(
                                medicineCabinetGetAllPrescriptions({
                                    showNewRxModal: false,
                                    epostNumFamilyMember: activeDependentTab
                                })
                            );
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <BirdiModalContent
                                            icon={'alert'}
                                            title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                            body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onSuccess: (data: any) => {
                            const extendedCart = processCart(
                                data,
                                accountHasInsurance,
                                prescriptions,
                                drugDiscountPrices,
                                accountPlans,
                                undefined,
                                cardOrderShippingAddress?.zipcode || ''
                            );
                            TrackCheckoutStep({
                                stepName: 'add',
                                step: '1',
                                carts: extendedCart,
                                prescriptions: prescriptions,
                                t: t,
                                shippingCost: '0',
                                accountHasInsurance: accountHasInsurance
                            });

                            dispatch(
                                getCartRoutine.trigger({
                                    onSuccess: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                        dispatch(closeModal({}));
                                    }
                                })
                            );
                        }
                    })
                );
            } else {
                dispatch(setMedicineCabinetIsBusy(false));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isRxInCartItemsObject,
            accountHasInsurance,
            activeDependentTab,
            cartItemsObject,
            drugDiscountPrices,
            prescriptions,
            cardOrderShippingAddress?.zipcode,
            accountPlans
        ]
    );

    // Function to prepare generic error modal
    const showGenericErrorModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                        note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    }, [dispatch, t]);

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const hasAnyRxAutoRefill = filteredRxs?.some((rx) => rx.autoRefillEnabled) || false;
            handleToggleAutoRefill(
                { rxNumber: rxNumber, rxSeqNum: rxSeqNumber },
                autoRefillEnabled,
                hasAnyRxAutoRefill,
                <AutoRefillTermsAndConditionsModal t={t} />,
                isRenew,
                showGenericErrorModal
            );
        },
        [handleToggleAutoRefill, t, showGenericErrorModal, filteredRxs]
    );

    // Filter Rx according to selection (all, expired and auto refill)
    const handleFilterPrescriptions = (
        prescriptions: PrescriptionCardProps[] | null,
        tab: string,
        dependent: string
    ): PrescriptionCardProps[] | null => {
        if (prescriptions) {
            const prevPrescriptions = dependent
                ? prescriptions.filter((prescription) => dependent === prescription.fullRxItem.epostPatientNum)
                : prescriptions;
            switch (tab) {
                case 'all':
                    return prevPrescriptions;
                case 'auto-refills':
                    return prevPrescriptions.filter(
                        (prescription) =>
                            prescription.fullRxItem.autoRefillEnabled === true &&
                            prescription.fullRxItem.autoRefillEligible === true
                    );
                case 'expired':
                    return prevPrescriptions.filter(
                        (prescription) =>
                            prescription.fullRxItem.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ &&
                            isRxExpired(prescription.fullRxItem.rxExpirationDate)
                    );
                default:
                    return prevPrescriptions;
            }
        }

        return null;
    };

    // Show Rxs depending on the family member selected
    const handleTabItemClick = useCallback(
        async (tab: string, dependent: string) => {
            if (dependent !== activeDependentTab) {
                await setRxsLoaded(false);

                dispatch(
                    medicineCabinetGetAllPrescriptions({
                        showNewRxModal: false,
                        epostNumFamilyMember: dependent,
                        onSuccess: () => {
                            setRxsLoaded(true);
                            dispatch(setMedicineCabinetIsBusy(false));
                        },
                        onFailure: () => {
                            setRxsLoaded(true);
                            dispatch(setMedicineCabinetIsBusy(false));
                        }
                    })
                );

                const activeDependent = familyMembers?.filter((member) => {
                    return member.value === dependent;
                })[0];
                setActivePlanAlias(activeDependent?.planAlias);
            } else {
                setRxsLoaded(true);
            }
            dispatch(setMedicineCabinetFilterTab(tab));
            dispatch(setMedicineCabinetActiveTab(dependent));
            setFilteredRxs(handleFilterPrescriptions(orderedRxs, tab, dependent));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeDependentTab, familyMembers, orderedRxs]
    );

    return (
        <div className="prescription-list-v2">
            <PrescriptionTabs
                activeTab={filterTab}
                activeDependentTab={activeDependentTab}
                isPrescriptionsAvailable={prescriptions.length > 0}
                onTabItemChange={handleTabItemClick}
                hasAutoRefillFlag={hasPlanAutoRefillFlag(`${activeDependentTab}`, profileObject!)}
                dependents={dependentTabs}
                myEpostPatientNum={profileObject?.epostPatientNum}
            />

            {prescriptions.length > 0 ? (
                <>
                    <div className="prescription-list__cta-toggle">
                        <Button
                            label={buttonLabel}
                            type={'button'}
                            variant="text-blue"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </div>

                    {rxsLoaded && medicineCabinetPopulated ? (
                        <PrescriptionsCards isCollapsed={isCollapsed}>
                            {filteredRxs && filteredRxs.length > 0 ? (
                                filteredRxs
                                    .sort((a, b) => {
                                        const isAinCart = isRxInCart(a);
                                        const isBinCart = isRxInCart(b);
                                        if (isAinCart !== isBinCart) {
                                            return isAinCart ? -1 : 1;
                                        }
                                        return a.sortOrder - b.sortOrder;
                                    })
                                    .map((prescription, index) => (
                                        <PrescriptionCard
                                            key={`medicine-cabinet-rx-card-v2-${prescription.rxNumber}-${index}`}
                                            isCollapsed={isCollapsed}
                                            {...prescription}
                                            autoRefillToggle={onChangeAutoRefill}
                                            planAlias={activePlanAlias}
                                            isCaliforniaUser={isCaliforniaUser}
                                            accountHasInsurance={accountHasInsurance}
                                            zipcode={zipcode}
                                            autoRefillToggleBusy={autoRefillToggleBusy}
                                            planAllowsAutoRefill={
                                                profileObject
                                                    ? hasPlanAutoRefillFlag(
                                                          prescription.fullRxItem.epostPatientNum,
                                                          profileObject
                                                      )
                                                    : false
                                            }
                                            isAddingToCart={isAddingToCart}
                                            addToCart={() => {
                                                handleAddToCartClick(
                                                    prescription.rxNumber,
                                                    prescription.fullRxItem.epostPatientNum
                                                );
                                            }}
                                            isRxInCart={isRxInCart(prescription)}
                                            orderStatus={
                                                isRxInCart(prescription) ? RX_STATUS.IN_CART : prescription.orderStatus
                                            }
                                        />
                                    ))
                            ) : filteredRxs?.length === 0 && filterTab === 'auto-refills' ? (
                                <>
                                    <div>{t('components.prescriptionsList.empty.autoRefills')}</div>
                                    <Button
                                        chevron="right"
                                        type="button"
                                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                                        variant={'text-blue'}
                                        onClick={() => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        }}
                                    />
                                </>
                            ) : filteredRxs?.length === 0 && filterTab === 'expired' ? (
                                <>
                                    <div>{t('components.prescriptionsList.empty.expired')}</div>
                                    <Button
                                        chevron="right"
                                        type="button"
                                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                                        variant={'text-blue'}
                                        onClick={() => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        }}
                                    />
                                </>
                            ) : (
                                !prescriptions?.length &&
                                !filteredRxs?.length &&
                                filterTab === 'all' && (
                                    <>
                                        <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                                        <Button
                                            chevron="right"
                                            type="button"
                                            label={t('components.dashboardCtaBlock.titles.transferPre')}
                                            variant={'text-blue'}
                                            onClick={() => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'Transfer' });
                                                navigate('/secure/prescription');
                                            }}
                                        />
                                    </>
                                )
                            )}
                        </PrescriptionsCards>
                    ) : (
                        <LoadingMessage isVisible={true} text={t('pages.medicineCabinet.loading')} />
                    )}
                </>
            ) : rxsLoaded && medicineCabinetPopulated ? (
                <>
                    <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>

                    <Button
                        chevron="right"
                        type="button"
                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                        variant={'text-blue'}
                        onClick={() => {
                            resetForm();
                            setPrescriptionFlowType({ flowType: 'Transfer' });
                            navigate('/secure/prescription');
                        }}
                    />
                </>
            ) : prescriptions.length === 0 && !medicineCabinetIsLoading ? (
                <>
                    <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                    <Button
                        chevron="right"
                        type="button"
                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                        variant={'text-blue'}
                        onClick={() => {
                            resetForm();
                            setPrescriptionFlowType({ flowType: 'Transfer' });
                            navigate('/secure/prescription');
                        }}
                    />
                </>
            ) : (
                <LoadingMessage isVisible={true} text={t('pages.medicineCabinet.loading')} />
            )}
        </div>
    );
};

export default PrescriptionsList;
